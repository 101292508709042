import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { auth } from './base';
import { db } from './base';
import { onAuthStateChanged } from '@firebase/auth';
import { doc,
         getDocs,
         collection,
         query,
         where,
         deleteDoc,
         updateDoc } from "firebase/firestore";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';



// const useStyles = makeStyles(theme =>
//     createStyles({
//       horizontalVertical: {
//           '@media (max-width:799px)': {
//             paddingTop: '2rem', 
//             padding: '1rem',
//             display: 'flex',
//             flexDirection: 'column', 
//             rowGap: '1rem',
//             columnGap: '1rem',
//           },
//           '@media (min-width:800px)': {
//             paddingTop: '2rem',
//             padding: '1rem', 
//             display: 'flex', 
//             flexDirection: 'row',
//             flexWrap: 'wrap',
//             rowGap: '1rem',
//             columnGap: '1rem',
//           },
//       }
//     })
//   );

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


export default function Review(){

    const history = useHistory();
    const [category, setCategory] = useState("test");
    const [firebaseData, setFirebaseData] = useState([]);
    const [answerKeyData, setAnswerKeyData] = useState([]);
    const [nilaiBaru, setNilaiBaru] = useState(0);
    const [open, setOpen] = useState(false);
    const Input = styled('input')({
        display: 'none',
      });


    const categoryNames = (kode) => ({
        "test": "TEST EXAM",
        "bidtk": "Bahasa Indonesia, Level TK",
        "bida": "Bahasa Indonesia, Level A",
        "bidb": "Bahasa Indonesia, Level B",
        "bidc": "Bahasa Indonesia, Level C",
        "bidd": "Bahasa Indonesia, Level D",
        "bide": "Bahasa Indonesia, Level E",
        "engtk": "English, Preparatory Level",
        "enga": "English, Level A",
        "engb": "English, Level B",
        "engc": "English, Level C",
        "engd": "English, Level D",
        "enge": "English, Level E",
        "mattk": "Matematika, Level TK",
        "mata": "Matematika, Level A",
        "matb": "Matematika, Level B",
        "matc": "Matematika, Level C",
        "matd": "Matematika, Level D",
        "mate": "Matematika, Level E",
        "saia": "Sains, Level A",
        "saib": "Sains, Level B",
        "saic": "Sains, Level C",
        "said": "Sains, Level D",
        "sosd": "Ilmu Sosial, Level D",
        "sose": "Sosial Politik, Level E",
    })[kode]
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {

        onAuthStateChanged(auth, (user) => {
        if (!user) {
            history.push('/');  
        } else {
            console.log(`${user.email} is logged in`);
        }
    });

    }, [])


    useEffect(() => { 
        const gettingData = async () => {
            const q = query(collection(db, "exam"), where("category", "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setFirebaseData(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };

        const gettingAnswerKey = async () => {
            const q = query(collection(db, "answerkey"), where("category", "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setAnswerKeyData(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };
        
        gettingData();
        gettingAnswerKey();

    }, [category]);


    const handleChooseCategory = (e) => {
        setCategory(e.target.value);
    }

    

    const handleDelete = async (id) => {
        const fileRef = doc(db, "exam", id);
        await deleteDoc(fileRef);
        console.log("Deleted");
        console.log(id);
    }

    
    const handleSubmitAK = () => {
        alert('not available')
    }

    const handleSubmitScore = async (id) => {
        const newScore = {score : Number(nilaiBaru)};
        const scoreRef = doc(db, "answerkey", id);
        await updateDoc(scoreRef, newScore);
        console.log('success');
        console.log(nilaiBaru);
    }



    return(
        
        <div style={{paddingTop: '2rem'}}>
            <div>
                <div style={{display:'flex'}}>
                    <FormControl sx={{ m: 1, minWidth: 160 }}>
                        <InputLabel>Categories</InputLabel>
                            <Select
                            label="Categories"
                            name="category"
                            id="categories"
                            value={category}
                            onChange={handleChooseCategory}
                            >
                                <MenuItem value={"bidtk"}>Bhs Ind - TK</MenuItem>
                                <MenuItem value={"bida"}>Bhs Ind - Level A</MenuItem>
                                <MenuItem value={"bidb"}>Bhs Ind - Level B</MenuItem>
                                <MenuItem value={"bidc"}>Bhs Ind - Level C</MenuItem>
                                <MenuItem value={"bidd"}>Bhs Ind - Level D</MenuItem>
                                <MenuItem value={"bide"}>Bhs Ind - Level E</MenuItem>
                                <MenuItem value={"engtk"}>English - TK</MenuItem>
                                <MenuItem value={"enga"}>English - Level A</MenuItem>
                                <MenuItem value={"engb"}>English - Level B</MenuItem>
                                <MenuItem value={"engc"}>English - Level C</MenuItem>
                                <MenuItem value={"engd"}>English - Level D</MenuItem>
                                <MenuItem value={"enge"}>English - Level E</MenuItem>
                                <MenuItem value={"mattk"}>Math - TK</MenuItem>
                                <MenuItem value={"mata"}>Math - Level A</MenuItem>
                                <MenuItem value={"matb"}>Math - Level B</MenuItem>
                                <MenuItem value={"matc"}>Math - Level C</MenuItem>
                                <MenuItem value={"matd"}>Math - Level D</MenuItem>
                                <MenuItem value={"mate"}>Math - Level E</MenuItem>
                                <MenuItem value={"saia"}>Sains - Level A</MenuItem>
                                <MenuItem value={"saib"}>Sains - Level B</MenuItem>
                                <MenuItem value={"saic"}>Sains - Level C</MenuItem>
                                <MenuItem value={"said"}>Sains - Level D</MenuItem>
                                <MenuItem value={"sosd"}>Sos - Level D</MenuItem>
                                <MenuItem value={"sose"}>SosPol - Level E</MenuItem>
                            </Select>
                    </FormControl>
                </div> 
            </div>
            <div>
                <div>
                    <Typography variant="h4">
                        {categoryNames(category)} REVIEW
                    </Typography>
                </div>

                <ul style={{listStyle: 'none', display: 'block'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div>
                        {firebaseData.map((soal) => {
                                return (
                                    <li key={soal.nomorSoal}>
                                        <div style={{ padding: '1rem'}}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div>
                                                    <Typography variant="h2">
                                                        {soal.nomorSoal}
                                                    </Typography>
                                                </div>
                                                <Paper elevation={3} sx={{marginTop: '2rem', padding: '3rem', width: '50vw', height: '60vh'}}>
                                                    <div style={{display: 'flex', 
                                                                justifyContent: 'center', 
                                                                padding: '1rem'}}
                                                                >
                                                        <Button 
                                                            variant="contained"
                                                            onClick={() => handleDelete(soal.id)} 
                                                            style={{marginLeft: '2rem', 
                                                                    height: '3rem',
                                                                    backgroundColor: '#e0232a'
                                                                    }}>
                                                                DELETE THIS QUESTION
                                                        </Button>
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                                        <img src={soal.imgurl} alt="test" style={{width: 600, maxHeight: '50vh'}}/>
                                                        <div style={{display: 'flex', 
                                                                    flexDirection: 'column',
                                                                    width: '7vw',
                                                                    paddingLeft: '1rem'}}
                                                                    >
                                                            <div>
                                                                <label htmlFor="changeJpegButton">
                                                                <Input  accept="image/*" 
                                                                        id="changeJpegButton" 
                                                                        multiple 
                                                                        type="file" 
                                                                        
                                                                        />
                                                                <Button variant="contained"
                                                                        component="span" 
                                                                        disabled
                                                                        style={{height: '3rem'}}>
                                                                                    CHANGE JPEG
                                                                </Button>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Paper>
                                            </div>
                                        </div>   
                                    </li>
                                    );
                                }
                            )
                        }
                        </div>
                        <div>
                            {answerKeyData.map((jawaban) => {
                                return (
                                    <div style={{padding: '1rem'}}>
                                        <Paper key={jawaban.id} elevation={3} sx={{marginTop: '2rem', padding: '3rem', width: '15vw', height: '60vh'}}>
                                            <div style={{border: '1px solid black', borderRadius: 20}}>
                                                <div style={{padding: '1rem'}}>
                                                    <div style={{display: 'flex', 
                                                                flexDirection: 'row',
                                                                alignItems: 'center'}}>
                                                        <Typography variant="subtitle2" m="0.5rem">
                                                            Nomor soal: 
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            {jawaban.nomorSoal}
                                                        </Typography>
                                                    </div>
                                                    <div style={{display: 'flex', 
                                                                flexDirection: 'row',
                                                                alignItems: 'center'}}>
                                                        <Typography variant="subtitle2" m="0.5rem">
                                                            Answer Key: 
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            {jawaban.answerKey}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
                                                <Button variant="contained"
                                                        onClick={handleOpen}
                                                        style={{marginLeft: '1rem', height: '3rem'}}>
                                                            CHANGE ANSWER KEY
                                                </Button>
                                                <div>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                        BackdropProps={{style: {backgroundColor: 'white', opacity: 0.1}}}
                                                        >
                                                        <Box sx={style}>
                                                            <Typography id="modal-modal-title" 
                                                                        variant="h6" 
                                                                        component="h2">
                                                                            Submit new answer key:
                                                            </Typography>
                                                            <TextField id="newAnswerKey" 
                                                                        label="New Answer Key" 
                                                                        variant="outlined"
                                                                        sx = {{margin: '1rem'}}
                                                                        />
                                                            <Button variant="contained" 
                                                                    onClick={handleSubmitAK}
                                                                    disabled
                                                                    >
                                                                SUBMIT NEW ANSWER KEY
                                                            </Button>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div style={{marginTop: '1rem', border: '1px solid black', borderRadius: 20}}>
                                                <div style={{padding: '1rem'}}>
                                                    <div>
                                                        <div style={{display: 'flex', 
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center'}}>
                                                            <Typography variant="subtitle2" m="0.5rem">
                                                                {`Score for: ${jawaban.id}`} 
                                                            </Typography>
                                                            <Typography variant="h6">
                                                                {jawaban.score}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
                                                        <TextField 
                                                            id="outlined-basic" 
                                                            label="   Score" 
                                                            defaultValue=""
                                                            variant="outlined"
                                                            onChange={e => setNilaiBaru(e.target.value)}
                                                            />
                                                    </div>
                                                    <div>
                                                        <Button variant="contained" 
                                                                style={{height: '3rem'}}
                                                                onClick={() => {handleSubmitScore(jawaban.id)}}
                                                                >
                                                                    SUBMIT SCORE
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                    </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </ul>
            </div>
        </div>
        
    )
}