import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';




const firebaseApp = initializeApp({ 
    apiKey: "AIzaSyAkYfdcOoZTXfjGDP6qgvrvJZXRpzV1kb0",
    authDomain: "deinstitute-7573e.firebaseapp.com",
    projectId: "deinstitute-7573e",
    storageBucket: "deinstitute-7573e.appspot.com",
    messagingSenderId: "874038855132",
    appId: "1:874038855132:web:d564cf1ef2e145da75fb19",
});



const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);



export { auth,
         db,
         storage,
         functions
         };