import React, { useEffect, useState } from 'react';
import { auth } from './base';
import { db } from './base';
import { onAuthStateChanged } from '@firebase/auth';
import { doc, setDoc } from "firebase/firestore"; 
import { getStorage, 
         ref, 
         uploadBytes,
         getDownloadURL } from "firebase/storage";
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { makeStyles, createStyles } from '@mui/styles';







const useStyles = makeStyles(theme =>
    createStyles({
      horizontalVertical: {
          '@media (max-width:799px)': {
            paddingTop: '2rem', 
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column', 
            rowGap: '1rem',
            columnGap: '1rem',
          },
          '@media (min-width:800px)': {
            paddingTop: '2rem',
            padding: '1rem', 
            display: 'flex', 
            flexDirection: 'row',
            flexWrap: 'wrap',
            rowGap: '1rem',
            columnGap: '1rem',
          },
      }
    })
  );

export default function Questions() {

    const classes = useStyles();
    const history = useHistory();
    const storage = getStorage();
    const [category, setCategory] = useState('');

    const Input = styled('input')({
        display: 'none',
      });

    // dynamic inputField
    const [inputField, setInputField] = useState([{
        fieldNum: 1,
        nomorSoal: "",
        imgurl: "",
        answerKey: "",
        score: 0
    }]);

    // cek apakah ada user yg login
    useEffect(() => {

        onAuthStateChanged(auth, (user) => {
        if (!user) {
            history.push('/');  
        } else {
            console.log(`${user.email} is logged in`);
        }
    });

    }, [])


    // handle input change
    const handleInputChange = (e, i) => {
        const values = [...inputField];
        values[i][e.target.name] = e.target.value;    
        setInputField(values);
    };

    // tombol plus unt menambah input field
    const handleAddClick = (fieldNum) => {
        setInputField([...inputField, 
            { fieldNum: fieldNum + 2,  
              nomorSoal: "",
              imgurl: "",
              answerKey: "",
              score: 0
            }]);
    };

    // tombol minus unt mengurangi input field
    const handleRemoveClick = (i) => {
        const values = [...inputField];
        values.splice(i, 1);
        setInputField([...values]);
    };



    // menyimpan soal dan answerKey ke firestore
    const submitData = async () => {
        // inputField.map(async(field,i) => {
        let a = inputField.length;
        let i = a-1;
        await setDoc(doc(db, "exam", `${category}${inputField[i].nomorSoal}`), {
            nomorSoal : inputField[i].nomorSoal,
            category : category
            }, { merge: true }
        );
        await setDoc(doc(db, "answerkey", `${category}${inputField[i].nomorSoal}`), {
            answerKey : inputField[i].answerKey,
            category : category,
            nomorSoal : inputField[i].nomorSoal
            }, { merge: true }
        );
        
        console.log("Submitted");       
        }
        // )};



    //simpan file2 gambar soal
    const handleAddImage = async(e) => {
        // inputField.map(async(field,i) => {
            let a = inputField.length;
            let i = a-1;
            const file = e.target.files[0];
            const uploadAnImgRef = ref(storage, `exam/${category}/${category}${inputField[i].nomorSoal}`)
            await uploadBytes(uploadAnImgRef, file).then(() => {
                  getDownloadURL(uploadAnImgRef).then((downloadURL) => {
                        setDoc(doc(db, "exam", `${category}${inputField[i].nomorSoal}`), {
                        imgurl : downloadURL}, {merge: true});
                  });
                });
            // });
        }
        




    return(
        <div> 
            <div style={{padding: '1rem', width: 'fit-content', margin:'auto'}}>
                <Paper sx={{padding: '0.5rem'}}>
                    <h3 style={{fontSize: '1.8rem', fontWeight: 'bold', color: 'red'}}>
                        IMPORTANT:
                    </h3>
                    <p style={{color: 'red'}}>
                        If the <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>question number</span> is one digit, please input 0 (zero) before it, example: 01, 02... 09
                    </p>
                </Paper>
            </div>

            <div style={{display:'flex', justifyContent: 'center', width: 'fit-content', margin: 'auto'}}>
                <Paper sx={{padding: '0.5rem'}}>
                    <FormControl sx={{ marginTop: 2, minWidth: '80%' }}>
                        <InputLabel>Subject & Level</InputLabel>
                            <Select
                            label="Subject & Level"
                            name="category"
                            id="categories"
                            value={category}
                            onChange={e => setCategory(e.target.value)}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value={"bidtk"}>Bhs Ind - TK</MenuItem>
                                <MenuItem value={"bida"}>Bhs Ind - Level A</MenuItem>
                                <MenuItem value={"bidb"}>Bhs Ind - Level B</MenuItem>
                                <MenuItem value={"bidc"}>Bhs Ind - Level C</MenuItem>
                                <MenuItem value={"bidd"}>Bhs Ind - Level D</MenuItem>
                                <MenuItem value={"bide"}>Bhs Ind - Level E</MenuItem>
                                <MenuItem value={"engtk"}>English - TK</MenuItem>
                                <MenuItem value={"enga"}>English - Level A</MenuItem>
                                <MenuItem value={"engb"}>English - Level B</MenuItem>
                                <MenuItem value={"engc"}>English - Level C</MenuItem>
                                <MenuItem value={"engd"}>English - Level D</MenuItem>
                                <MenuItem value={"enge"}>English - Level E</MenuItem>
                                <MenuItem value={"mattk"}>Math - TK</MenuItem>
                                <MenuItem value={"mata"}>Math - Level A</MenuItem>
                                <MenuItem value={"matb"}>Math - Level B</MenuItem>
                                <MenuItem value={"matc"}>Math - Level C</MenuItem>
                                <MenuItem value={"matd"}>Math - Level D</MenuItem>
                                <MenuItem value={"mate"}>Math - Level E</MenuItem>
                                <MenuItem value={"saia"}>Sains - Level A</MenuItem>
                                <MenuItem value={"saib"}>Sains - Level B</MenuItem>
                                <MenuItem value={"saic"}>Sains - Level C</MenuItem>
                                <MenuItem value={"said"}>Sains - Level D</MenuItem>
                                <MenuItem value={"sosd"}>Sos - Level D</MenuItem>
                                <MenuItem value={"sose"}>SosPol - Level E</MenuItem>
                            </Select>
                    </FormControl>
                    <p style={{color: 'red', fontSize: '0.8rem'}}>
                        You may submit as many questions as you'd like,
                    </p>
                    <p style={{color: 'red', fontSize: '0.8rem'}}>
                        but only from <span style={{fontWeight: 'bold', fontSize: '1rem', textDecoration: 'underline'}}>one category</span> at a time
                    </p>
                </Paper>
            </div> 

            <div className={classes.horizontalVertical}>
                {inputField.map((field, i) => {
                    return(
                        <div key={field.fieldNum}>
                            <Paper elevation={3} sx={{padding: '1rem'}}>
                                <div>
                                    <TextField  label="Question Number" 
                                                variant="outlined" 
                                                name="nomorSoal"
                                                value={field.nomorSoal}
                                                onChange={e => handleInputChange(e, i)}
                                                sx={{width: '80%'}}
                                                />
                                </div>

                                <div style={{display:'flex', justifyContent: 'center'}}>
                                    <FormControl sx={{ m: 1, minWidth: '80%' }}>
                                        <InputLabel>Answer Key</InputLabel>
                                            <Select
                                            label="Answer Key"
                                            name="answerKey"
                                            value={field.answerKey}
                                            onChange={e => handleInputChange(e, i)}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                <MenuItem value={"a"}>A</MenuItem>
                                                <MenuItem value={"b"}>B</MenuItem>
                                                <MenuItem value={"c"}>C</MenuItem>
                                                <MenuItem value={"d"}>D</MenuItem>
                                                <MenuItem value={"e"}>E</MenuItem>
                                            </Select>
                                    </FormControl>
                                </div>                                            

                                <div style={{padding: '1rem'}}>
                                    <label htmlFor="uploadImage">
                                        <Input  accept="image/*" 
                                                id="uploadImage"
                                                type="file" 
                                                onChange={e => handleAddImage(e, i)}
                                                />
                                                    <Button variant="contained" 
                                                            component="span"
                                                            >
                                                                UPLOAD IMAGE
                                                    </Button>
                                    </label>  
                                </div>

                                <div style={{padding: '0.5rem'}}>
                                    <Button 
                                        variant="contained"
                                        value="Submit"
                                        onClick={submitData}
                                        >
                                        SUBMIT
                                    </Button>
                                </div>

                                <div>
                                    {inputField.length !== 1 && 
                                        <Button 
                                        onClick={() => handleRemoveClick(i)}
                                        >
                                            <IndeterminateCheckBoxIcon fontSize="large"/>
                                        </Button>}
                                    {inputField.length - 1 === i && 
                                        <Button 
                                        onClick={() => handleAddClick(i)}
                                        >
                                            <AddBoxIcon fontSize="large"/>
                                        </Button>}
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', gap: '0.5rem'}}>
                                    <div style={{fontSize: '0.7rem'}}>CHECKER</div>
                                    <div style={{fontSize: '1rem'}}>{inputField[i].nomorSoal}</div>
                                    <div style={{fontSize: '1rem'}}>{inputField[i].answerKey}</div>
                                </div>
                            </Paper>
                        </div>
                )})}
            </div>

            
            <div style={{height: '10rem'}}/>
        </div>
    )
}
