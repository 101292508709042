import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Questions from './questions';
import Navbar from './navbar';
import OlimpusHeader from './olimpusheader';
import Home from './home';
import Review from './review';
import AdminPage from './adminpage';
import Result from './result';





export default function App() {
  return (
    <div className="App">
            <Router>
                <div className="navbar">
                    <Navbar/>
                </div>
                <div className="olimpusheader">
                    <OlimpusHeader/>
                </div>

                <div className="content">
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/questions">
                            <Questions />
                        </Route>
                        <Route path="/review">
                            <Review />
                        </Route>
                        <Route path="/result">
                            <Result />
                        </Route>
                        {/* <Route path="/adminpage">
                            <AdminPage />
                        </Route> */}
                    </Switch>
                </div>
            </Router>
    </div>
  );
}

