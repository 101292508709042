import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router';
import { auth } from './base';
import { signInWithEmailAndPassword, onAuthStateChanged } from '@firebase/auth';






export default function Home(){
    
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    function handleClick() {
        
        signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            history.push('/questions');  
        })
        .catch((error) => {
            console.log(error);
        });

    }

    useEffect(() => {

            onAuthStateChanged(auth, (user) => {
            if (user) {
                history.push('/questions');  
            } else {
                console.log('auth check somehow had failed');
            }
        });
    }, []);

    return(
        <div style={{paddingTop: '5rem'}}>
            <Box
            component="form"
            sx={{ '& > :not(style)': { m: 1, width: '25ch' }}}
            noValidate
            autoComplete="off"
            >
                <TextField 
                        id="username" 
                        label="Silakan isi username" 
                        variant="outlined" 
                        value={email}
                        onChange={ e => setEmail(e.target.value) }
                        />
                <TextField 
                        id="password" 
                        label="Silakan isi password" 
                        variant="outlined" 
                        type="password"
                        value={password}
                        onChange={ e => setPassword(e.target.value) }
                        />
                <Button
                    onClick={handleClick}
                    variant="contained"
                    sx={{backgroundColor: '#b12773'}}
                    >
                    LOGIN
                </Button>
            </Box>
        </div>
  );
}
