import React, { useState, useEffect } from 'react';
import { db } from './base';
import { getDocs,
         collection,
         query,
         where,
         documentId } from "firebase/firestore";
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { isEqual } from 'lodash';




export default function Result() {

    const [category, setCategory] = useState("bidtk");
    const [participants1, setParticipants1] = useState([]);
    const [participants2, setParticipants2] = useState([]);
    const [participants3, setParticipants3] = useState([]);
    const [participants4, setParticipants4] = useState([]);
    const [answerKey, setAnswerKey] = useState([]);

    useEffect(() => { 
        const gettingUsersData1 = async () => {
            const q = query(collection(db, "users"), where("categories1", "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setParticipants1(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };
        const gettingUsersData2 = async () => {
            const q = query(collection(db, "users"), where("categories2", "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setParticipants2(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };
        const gettingUsersData3 = async () => {
            const q = query(collection(db, "users"), where("categories3", "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setParticipants3(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };
        const gettingUsersData4 = async () => {
            const q = query(collection(db, "users"), where("categories4", "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setParticipants4(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };
        const gettingAnswerKey = async () => {
            const q = query(collection(db, "answerkeymatch"), where(documentId(), "==", `${category}`));
            const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                setAnswerKey(querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
                })
        };

        gettingUsersData1();
        gettingUsersData2();
        gettingUsersData3();
        gettingUsersData4();
        gettingAnswerKey();
        }, [category]);

    const handleChooseCategory = (e) => {
        setCategory(e.target.value);
    }



    const participantsAnswer = participants1.map((elem) => {return elem.answer});
    const oneparticipantAnswer = participantsAnswer.map(onepersonanswer => {return onepersonanswer});
    
    const individualanswer = oneparticipantAnswer.map(answer => {return answer});

    console.log(oneparticipantAnswer);

    const handleSelectName = (id) => {
        
    }



    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display:'flex'}}>
                    <FormControl sx={{ m: 1, minWidth: 160 }}>
                        <InputLabel>Categories</InputLabel>
                            <Select
                            label="Categories"
                            name="category"
                            id="categories"
                            value={category}
                            onChange={handleChooseCategory}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value={"bidtk"}>Bhs Ind - TK</MenuItem>
                                <MenuItem value={"bida"}>Bhs Ind - Level A</MenuItem>
                                <MenuItem value={"bidb"}>Bhs Ind - Level B</MenuItem>
                                <MenuItem value={"bidc"}>Bhs Ind - Level C</MenuItem>
                                <MenuItem value={"bidd"}>Bhs Ind - Level D</MenuItem>
                                <MenuItem value={"bide"}>Bhs Ind - Level E</MenuItem>
                                <MenuItem value={"engtk"}>English - TK</MenuItem>
                                <MenuItem value={"enga"}>English - Level A</MenuItem>
                                <MenuItem value={"engb"}>English - Level B</MenuItem>
                                <MenuItem value={"engc"}>English - Level C</MenuItem>
                                <MenuItem value={"engd"}>English - Level D</MenuItem>
                                <MenuItem value={"enge"}>English - Level E</MenuItem>
                                <MenuItem value={"mattk"}>Math - TK</MenuItem>
                                <MenuItem value={"mata"}>Math - Level A</MenuItem>
                                <MenuItem value={"matb"}>Math - Level B</MenuItem>
                                <MenuItem value={"matc"}>Math - Level C</MenuItem>
                                <MenuItem value={"matd"}>Math - Level D</MenuItem>
                                <MenuItem value={"mate"}>Math - Level E</MenuItem>
                                <MenuItem value={"saia"}>Sains - Level A</MenuItem>
                                <MenuItem value={"saib"}>Sains - Level B</MenuItem>
                                <MenuItem value={"saic"}>Sains - Level C</MenuItem>
                                <MenuItem value={"said"}>Sains - Level D</MenuItem>
                                <MenuItem value={"sosd"}>Sos - Level D</MenuItem>
                                <MenuItem value={"sose"}>SosPol - Level E</MenuItem>
                            </Select>
                    </FormControl>
                </div> 
                <div style={{display: 'flex'}}>
                    <Button variant="contained">
                        SCORE
                    </Button>
                </div>
            </div>
            
            <ul style={{listStyle: 'none'}}>
                {participants1.map((participant) => {
                    return (
                        <li key={participant.id}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <Button onClick={handleSelectName (participant.id)}>
                                            <div style={{margin: '0.2rem'}}>{participant.firstName}</div>
                                            <div style={{margin: '0.2rem'}}>{participant.middleName}</div>
                                            <div style={{margin: '0.2rem'}}>{participant.lastName}</div>
                                        </Button>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.school}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '15vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                       
                                    </div>
                                </Paper>
                            </div>
                        </li>
                    )})
                }  
                {participants2.map((participant) => {
                    return (
                        <li key={participant.id}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.firstName}</div>
                                        <div style={{margin: '0.2rem'}}>{participant.middleName}</div>
                                        <div style={{margin: '0.2rem'}}>{participant.lastName}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.school}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '15vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        
                                    </div>
                                </Paper>
                            </div>
                        </li>
                    )})
                }  
                {participants3.map((participant) => {
                    return (
                        <li key={participant.id}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.firstName}</div>
                                        <div style={{margin: '0.2rem'}}>{participant.middleName}</div>
                                        <div style={{margin: '0.2rem'}}>{participant.lastName}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.school}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '15vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        
                                    </div>
                                </Paper>
                            </div>
                        </li>
                    )})
                } 
                {participants4.map((participant) => {
                    return (
                        <li key={participant.id}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.firstName}</div>
                                        <div style={{margin: '0.2rem'}}>{participant.middleName}</div>
                                        <div style={{margin: '0.2rem'}}>{participant.lastName}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '28vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{margin: '0.2rem'}}>{participant.school}</div>
                                    </div>
                                </Paper>
                                <Paper sx={{ width: '15vw', margin: '0.2rem'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        
                                    </div>
                                </Paper>
                            </div>
                        </li>
                    )})
                } 
            </ul>
        </div>
    )
}


