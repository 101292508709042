import React from 'react';
import olimpusimg from './olimpusheading.png';



export default function OlimpusHeader() {

    return (
        <div style={{justifyContent: 'center'}}>
            <img src={olimpusimg} alt="Olimpus Heading" style={{height: '15vh'}}/>
        </div>
    );
}