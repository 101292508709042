import React, { useState, useEffect } from 'react';
import { Button, 
         Stack,
         Typography,
         createTheme,
         ThemeProvider } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { auth } from './base';
import { signOut, onAuthStateChanged } from '@firebase/auth';





const theme = createTheme();


theme.typography.h6 = {
    fontFamily: 'Hind Madurai',
    fontSize: '0.8rem',
    '@media (min-width:600px)': {
    fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    }
};




export default function Navbar() {

    const [userExist, setUserExist] = useState(false);
    

    const handleLogout = () => {
        signOut(auth).then(() => {
            console.log('user signed out');
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {

        onAuthStateChanged(auth, (user) => {
        if (!user) {
            setUserExist(false);  
        } else {
            setUserExist(true);
        }
    });

    }, [])


    return (

        <div>
            <Stack 
                spacing={2} 
                direction="row"
                justifyContent="flex-end"
                sx={{marginRight: {xs: '1rem', md: '5rem'}}}
                >
                    <ThemeProvider theme={theme}>
                            <Button variant="text" disabled={!userExist}>
                                <Typography variant="h6">
                                    <NavLink 
                                        to='/questions'  
                                        style={{ textDecoration: 'none', color: '#c61414' }} 
                                        exact
                                        >
                                            QUESTIONS
                                    </NavLink>
                                </Typography>
                            </Button>
                            
                            <Button variant="text" disabled={!userExist}>
                                <Typography variant="h6">
                                    <NavLink 
                                        to='/review'  
                                        style={{ textDecoration: 'none', color: '#c61414' }} 
                                        exact
                                        >
                                            REVIEW
                                    </NavLink>
                                </Typography>
                            </Button>

                            <Button variant="text" disabled={!userExist}>
                                <Typography variant="h6">
                                    <NavLink 
                                        to='/result'  
                                        style={{ textDecoration: 'none', color: '#c61414' }} 
                                        exact
                                        >
                                            RESULT
                                    </NavLink>
                                </Typography>
                            </Button>

                            <Button
                                onClick={handleLogout}
                                variant="text"
                                sx={{color: '#c61414'}}
                                disabled={!userExist}
                                >
                                    <Typography variant="h6">
                                        LOGOUT
                                    </Typography>
                            </Button>

                    </ThemeProvider>
            </Stack>
        </div>
    );
}

